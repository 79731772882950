<template>
    <div>
        <h2>ตั้งค่าการพิมพ์</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <div class="pa-8">
              <v-row>
                <v-col cols="12" md="6">
                    <v-select
                      v-model="formData.print_cover_size"
                      :items="paper"
                      label="รูปแบบ *"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      hide-details="auto"
                    >
                    </v-select>
                </v-col>                    
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <v-radio-group
                    v-model="formData.print_cover_product"
                    row
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  >
                    <v-radio
                      label="แสดง"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      label="ไม่แสดง"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>          
        </v-form>
    </div>
</template>
<script>
import { maxLengthValidator, minValueValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      validators: {
        required,
        maxLengthValidator,
        minValueValidator,
      },
      paper: [
        {
          id: 'A4',
          name: 'A4',
        },
        {
          id: 'A5',
          name: 'A5',
        },
        {
          id: 'A6',
          name: 'A6',
        },
        {
          id: 'sticker',
          name: 'Sticker 100x150',
        },
        {
          id: 'paperang',
          name: 'Paperang',
        },
      ],
      formData: {
        print_cover_size: 'A4',
        print_cover_product: true,
      },
    }
  },
  async created() {
    if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting')) this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { print_cover_size, print_cover_product } = await asyncGet('/setting/company')

      this.formData = {
        print_cover_size: print_cover_size,
        print_cover_product: !!print_cover_product,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        await asyncUpdate('/setting/print', this.formData)

        // window.location.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>